.learn-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.learn-heading {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #2c3e50; /* Deep blue-grey */
  font-weight: bold;
}

.learn-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.learn-card {
  background-color: #e8f6ef; /* Light green pastel */
  border-radius: 35px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.learn-card:nth-child(2) {
  background-color: #f5f3e7; /* Light beige pastel */
}

.learn-card:nth-child(3) {
  background-color: #fff0e5; /* Light peach pastel */
}

.learn-card:nth-child(4) {
  background-color: #edebff; /* Light lavender pastel */
}

.learn-card:nth-child(5) {
  background-color: #eaf2f8; /* Light blue pastel */
}

.learn-card:nth-child(6) {
  background-color: #fbe4e6; /* Light pink pastel */
}

.learn-card:nth-child(7) {
  background-color: #e8ebfa; /* Light yellow pastel */
}

.learn-card:nth-child(8) {
  background-color: #fff7e1; /* Light periwinkle pastel */
}

.learn-card:nth-child(9) {
  background-color: #f4e8ef; /* Light rose pastel */
}

.learn-card:nth-child(10) {
  background-color: #edf8fb; /* Light aqua pastel */
}

.learn-card:nth-child(11) {
  background-color: #f3e8fd; /* Light purple pastel */
}

.learn-card:nth-child(12) {
  background-color: #e6f5f9; /* Light turquoise pastel */
}

.learn-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.learn-icon {
  font-size: 2em;
}

.card-title {
  font-size: 1em;
  color: #4a4a4a; /* Soft dark grey */
}

.card-text {
  font-size: 1em;
  color: #7d7d7d; /* Light grey */
  line-height: 1.6;
}
