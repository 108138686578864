/* General Page Styles */
.services-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  color: #333;
  font-family: 'Arial', sans-serif;
  background-color: #f7f9fc; /* Light background color for a clean look */
}

/* Banner Section */
.service-banner {
  background: #594444; /* Deep blue background */
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.service-banner-title {
  font-size: 1.8em;
  font-weight: 700;
  color: #ffffff; /* White text for contrast */
  margin: 0;
}

/* Main Content Styles */
.services-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.service-section {
  background-color: #ffffff; /* White background for sections */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.service-section h2 {
  font-size: 1.5em;
  color: #594444; /* Bright blue for headings */
  margin-bottom: 15px;
  border-bottom: 2px solid #e0e0e0; /* Light grey border for a subtle touch */
  padding-bottom: 10px;
}

.service-section p {
  font-size: 1em;
  color: #4a4a4a; /* Dark grey for body text */
}

.service-section h3 {
  font-size: 1.6em;
  color: #9c8c8c; /* Green for subheadings to highlight */
  margin-bottom: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .services-page {
    padding: 40px 15px;
  }

  .service-section {
    padding: 20px;
  }

  .banner-title {
    font-size: 2em;
  }

  .service-section h2 {
    font-size: 1.3em;
  }

  .service-section h3 {
    font-size: 1.4em;
  }
}
