.skills-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  max-width: 1200px;
  margin: auto;
  gap: 2em; /* Reduced gap for smaller screens */
  background-color: #ebe1e1;
  border-radius: 1em;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.skill-item {
  display: flex;
  align-items: center;
  margin: 10px 20px; /* Adjust margin for better spacing */
  font-size: 1.2em;
  color: #594444;
  flex: 1 1 200px; /* Flex-grow, flex-shrink, and minimum width for responsiveness */
  text-align: center; /* Center align text on smaller screens */
}

.skill-item i {
  font-size: 1.5em;
  margin-right: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .skills-section {
    gap: 1em; /* Reduce gap on smaller screens */
    margin: 20px;
  }

  .skill-item {
    font-size: 1em; /* Adjust font size for smaller screens */
    margin: 10px 0;
    justify-content: center;
  }

  .skill-item i {
    font-size: 1.2em; /* Adjust icon size for smaller screens */
    margin-right: 5px;
  }
}
