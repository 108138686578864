.about-us-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.about-us-image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.about-us-image img {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.about-us-content {
  flex: 1;
  padding: 20px;
  color: #594444;
  max-width: 600px;
}

.about-us-title {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.about-us-text {
  font-size: 1.1em;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #333;
}

@media (max-width: 992px) {
  .about-us-container {
    flex-direction: column;
    text-align: center;
  }

  .about-us-image,
  .about-us-content {
    width: 100%;
  }

  .about-us-image img {
    max-width: 400px;
    border-radius: 15px;
  }

  .about-us-title {
    font-size: 1.8em;
  }
}

@media (max-width: 768px) {
  .about-us-title {
    font-size: 1.6em;
  }

  .about-us-text {
    font-size: 1em;
  }

  .about-us-image img {
    max-width: 100%;
    border-radius: 10px;
  }
}

@media (max-width: 480px) {
  .about-us-title {
    font-size: 1.4em;
  }

  .about-us-text {
    font-size: 0.9em;
  }

  .about-us-image img {
    max-width: 100%;
  }
}
