.course-list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  padding: 40px;
  background-color: #ebe1e1; /* Light grey background for the list */
}

.banner-text {
  text-align: center;
  font-size: 2.5rem; /* Slightly larger font */
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif; /* Modern, professional font */
  font-weight: 600;
  color: #333; /* Dark grey */
}

.course-card,
.free-course-card {
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
  width: 400px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: auto;
  border: 1px solid #e5e7eb; /* Light grey border */
}

.course-card:hover,
.free-course-card:hover {
  transform: translateY(-10px); /* Subtle hover effect */
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.course-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-bottom: 1px solid #e5e7eb;
}

.card-content {
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f3f4f6;
}

.course-title {
  font-size: 1.6em;
  margin-bottom: 10px;
  font-weight: 600;
  color: #333;
}

.course-description {
  font-size: 1em;
  color: #6b7280; /* Neutral grey for description */
  line-height: 1.6;
  margin-bottom: 15px;
}

.course-details {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 10px;
}

.course-details li {
  font-size: 0.95em;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #374151; /* Dark grey for course details */
}

.course-details .icon {
  color: #9ca3af; /* Light grey icon */
  font-size: 1.2em;
}

.card-buttons {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.card-buttons .btn {
  padding: 12px 20px;
  border-radius: 8px;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-readmore {
  background-color: #594444; /* Professional blue */
  color: white;
}

.btn-readmore:hover {
  background-color: #ebe1e1; /* Slightly darker on hover */
}

.btn-enroll {
  background-color: #ffffff;
  color: #594444;
  border: 2px solid #594444;
}

.btn-enroll:hover {
  background-color: #594444;
  color: white;
}

.explore-button-container {
  text-align: center;
  margin-top: 30px;
}

.btn-explore-more {
  padding: 12px 25px;
  background-color: #594444;
  color: white;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.1em;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-explore-more:hover {
  background-color: #4338ca;
  color: #ffffff;
}

/* Free Alert Styling */
.free-alert {
  background-color: #ff6347; /* Softer red */
  color: white;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 0.9em;
  position: absolute;
  top: 15px;
  right: 15px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .course-list {
    gap: 30px;
    padding: 20px;
  }

  .course-card,
  .free-course-card {
    width: 320px;
  }

  .banner-text {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .course-card,
  .free-course-card {
    width: 100%;
  }

  .course-title {
    font-size: 1.4em;
  }

  .course-description {
    font-size: 0.9em;
  }

  .card-buttons {
    display: block;
  }

  .card-buttons .btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .banner-text {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .course-title {
    font-size: 1.3em;
  }

  .course-description {
    font-size: 0.85em;
  }

  .banner-text {
    font-size: 1.5rem;
  }
}
