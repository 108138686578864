.cancellation-policy-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  margin-top: 80px;
}

.cancellation-policy-container h1 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.cancellation-policy-container h2 {
  font-size: 1.5em;
  color: #555;
  margin-top: 20px;
  margin-bottom: 10px;
}

.cancellation-policy-container p {
  font-size: 1em;
  color: #666;
  line-height: 1.6;
}

.cancellation-policy-container ul {
  list-style-type: none;
  padding: 0;
}

.cancellation-policy-container li {
  font-size: 1em;
  color: #333;
  margin-bottom: 10px;
}

.cancellation-policy-container a {
  color: #007bff;
  text-decoration: none;
}

.cancellation-policy-container a:hover {
  text-decoration: underline;
}

.policy-update-date {
  font-weight: bold;
  color: #333;
}
