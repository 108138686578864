.why-choose-us {
  background-color: #ffffff;
  padding: 60px 20px;
  text-align: center;
}

.section-title {
  font-size: 2.5em;
  color: #594444;
  margin-bottom: 40px;
  font-weight: bold;
}

.reasons-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.reason-card {
  background-color: #ebe1e1;
  border-radius: 15px;
  padding: 30px;
  width: 250px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.icon-container {
  background-color: #594444;
  color: #ffffff;
  border-radius: 50%;
  padding: 15px;
  font-size: 2em;
  margin-bottom: 20px;
}

.reason-title {
  font-size: 1.5em;
  color: #9c8c8c;
  margin-bottom: 15px;
}

.reason-description {
  font-size: 1em;
  color: #594444;
}

/* Responsive styles */
@media (max-width: 768px) {
  .section-title {
    font-size: 2em; /* Adjust title size */
  }

  .reasons-container {
    justify-content: center; /* Center items on smaller screens */
    gap: 10px; /* Reduce gap */
  }

  .reason-card {
    width: 100%; /* Full width on smaller screens */
    max-width: 300px; /* Limit width */
    padding: 20px; /* Adjust padding */
  }

  .icon-container {
    font-size: 3em;
    padding: 1em;
  }

  .reason-title {
    font-size: 1.3em; /* Adjust title size */
  }

  .reason-description {
    font-size: 0.9em; /* Adjust text size */
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.5em; /* Further adjust title size */
  }

  .reasons-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
    gap: 20px; /* Increase gap between items */
  }

  .reason-card {
    width: 90%; /* More space for smaller screens */
    max-width: none; /* Remove max-width restriction */
    padding: 15px; /* Adjust padding */
  }

  .icon-container {
    font-size: 3em;
    padding: 1em;
  }

  .reason-title {
    font-size: 1.2em; /* Further adjust title size */
  }

  .reason-description {
    font-size: 0.8em; /* Further adjust text size */
  }
}
