.company-logos-section {
    padding: 40px;
    border-radius: 8px;
    max-width: 900px;
    margin: 30px auto;
  }
  
  .company-logos-title {
    text-align: center;
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .company-logos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Responsive grid layout */
    gap: 20px;
  }
  
  .company-logo-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .company-logo {
    max-width: 100px;
    height: auto;
    object-fit: contain;
  }
  