.blogs-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  background-color: #f5f5f5;
}

.blogs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.blog-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  max-width: 360px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  padding: 20px;
  text-align: left;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 15px;
}

.blog-title {
  font-size: 1.5em;
  margin-bottom: 15px;
  font-weight: 500;
}

.blog-description {
  font-size: 1em;
  color: #666;
  margin-bottom: 15px;
}

.blog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-date {
  font-size: 0.9em;
}

.blog-link {
  font-size: 0.9em;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.blog-link:hover {
  color: #402f2f; /* Adjust this color as needed */
}

@media (max-width: 768px) {
  .blogs-container {
    flex-direction: column;
    align-items: center;
  }

  .blog-card {
    width: 100%;
    max-width: 600px;
  }
}
