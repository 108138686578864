/* CourseSyllabus.css */

.syllabus-container {
  max-width: 800px;
  margin: auto;
  font-family: 'Roboto', sans-serif;
}

.syllabus-accordion {
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
}

.syllabus-summary {
  padding: 16px;
  background-color: #0056b3; /* Default summary color */
  color: #ffffff; /* White text color for better contrast */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 1rem;
}

.syllabus-header-text {
  font-size: 1rem;
}

.syllabus-details {
  padding: 16px;
  background-color: #ffffff; /* White background for details */
}

.syllabus-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.syllabus-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-size: 1rem;
}

.syllabus-time {
  color: #666; /* Dark gray for time visibility */
  font-size: 0.9rem;
}

.syllabus-name {
  width: 70%;
}

/* Media query for small screens */
@media (max-width: 600px) {
  .syllabus-list-item {
    font-size: 0.9rem;
  }

  .syllabus-time {
    font-size: 0.8rem;
  }
}


