.pagebanner-course-banner {
  margin-top: 80px;
  position: relative;
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  align-items: center;
  padding: 60px 20px;
  background-size: cover;
  background-position: center;
  min-height: 412px;
  color: white;
  text-align: left; /* Left align text */
}

.pagebanner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1;
}

.pagebanner-course-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  margin-left: 120px; /* Space from the left side */
}

.pagebanner-course-title {
  font-size: 3em;
  margin-bottom: 20px;
}

.pagebanner-course-description {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.pagebanner-enroll-button {
  padding: 15px 30px;
  font-size: 1.1em;
  background-color: #ffffff; /* Button color changed to white */
  color: #594444; /* Text color changed to match the banner */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.pagebanner-enroll-button:hover {
  background-color: #e0e0e0; /* Slightly darker white on hover */
  color: #594444; /* Text color remains the same */
}

@media (max-width: 768px) {
  .pagebanner-course-banner {
    padding: 40px 20px;
    text-align: center; /* Center text for tablet view */
    justify-content: center; /* Center content for tablet view */
  }

  .pagebanner-course-content {
    margin-left: 0; /* Remove left margin for tablet view */
  }

  .pagebanner-course-title {
    font-size: 2rem;
  }

  .pagebanner-course-description {
    font-size: 1rem;
  }

  .pagebanner-enroll-button {
    padding: 12px 24px;
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .pagebanner-course-banner {
    padding: 30px 10px;
  }

  .pagebanner-course-title {
    font-size: 1.75rem;
  }

  .pagebanner-course-description {
    font-size: 0.875rem;
  }

  .pagebanner-enroll-button {
    padding: 10px 20px;
    font-size: 0.9em;
  }
}
