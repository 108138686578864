.importance-section {
    background-color: #f9fafb;
    padding: 40px;
    border-radius: 10px;
    max-width: 900px;
    margin: 40px auto;
  }
  
  .importance-title {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
  }
  
  .importance-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .importance-item {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .importance-icon {
    font-size: 2rem;
    margin-right: 15px;
    color: #594444;
  }
  
  .importance-content h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .importance-content p {
    font-size: 1rem;
    color: #6b7280;
  }
  