.feedback-section {
    background-color: #f9f9f9;
    padding: 0 40px 40px 40px;
    border-radius: 8px;
    max-width: 800px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .feedback-title {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .feedback-list {
    margin-bottom: 30px;
  }
  
  .feedback-item {
    background-color: white;
    padding: 20px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .feedback-text {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .feedback-author {
    font-size: 1rem;
    font-weight: bold;
    color: #3f51b5;
  }
  