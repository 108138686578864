.blogPost-blog-post {
  max-width: 1000px; /* Increase the max width */
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
}

.blogPost-blog-header {
  margin-bottom: 20px;
}

.blogPost-blog-header h2 {
  font-size: 2.5em; /* Increase the font size */
  color: #333;
}

.blogPost-blog-meta {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

.blogPost-blog-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items */
  gap: 30px; /* Add gap between image and text */
}

.blogPost-blog-image {
  width: 100%;
  max-width: 800px; /* Increase the max width for larger images */
  margin: 0 auto; /* Center align the image */
}

.blogPost-blog-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px; /* Optional: rounded corners */
}

.blogPost-blog-image-placeholder {
  width: 100%;
  height: 400px; /* Larger placeholder for better alignment */
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 1.2em; /* Slightly larger font size */
  border-radius: 8px; /* Optional: rounded corners */
}

.blogPost-blog-text {
  font-size: 1.1em; /* Increase the font size for better readability */
  color: #333;
  line-height: 1.6; /* Improve readability */
  max-width: 800px; /* Constrain text width */
  text-align: center; /* Center align text */
}

.blogPost-blog-text div {
  margin-bottom: 20px;
}

.blogPostBanner {
  height: 80px;
}

.blogPost-blog-footer {
  margin-top: 30px; /* Add margin to separate footer from content */
  text-align: center; /* Center align footer text */
}

@media (max-width: 768px) {
  .blogPost-blog-post {
    padding: 10px;
  }

  .blogPost-blog-text {
    font-size: 1em; /* Adjust text size for smaller screens */
  }

  .blogPost-blog-image-placeholder {
    height: 300px; /* Adjust placeholder height for smaller screens */
  }
}
