.header-container {
  position: relative;
}

.custom-navbar {
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  transition: transform 0.3s ease-in-out;
  transform: translateY(0);
}

.custom-navbar.hidden {
  transform: translateY(-100%);
}

.custom-navbar.visible {
  transform: translateY(0);
}

.navbar-brand {
  margin-left: 1em;
}

.custom-navbar .navbar-brand img {
  max-height: 60px;
  width: 240px;
  height: 60px;
}

.custom-navbar .nav-link {
  color: #594444;
  text-decoration: none;
  padding: 10px 15px;
}

.custom-navbar .nav-link:hover {
  color: #402f2f;
}

.custom-navbar .nav-link:active {
  color: #402f2f;
}

.enroll-button {
  background-color: #594444;
  border-color: #594444;
  color: #ffffff;
  margin-left: 15px;
}

.enroll-button:hover {
  background-color: #402f2f;
  border-color: #402f2f;
  color: #ffffff;
}
