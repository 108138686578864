.homepagebanner {
  margin-top: 80px;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 130px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.homepagebanner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.homepagebanner-content {
  position: relative;
  z-index: 1;
}

.homepagebanner-title {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.homepagebanner-subtitle {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.homepagebanner-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.homepagebanner-btn {
  margin: 0;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  text-decoration: none;
}

.homepagebanner-btn-primary {
  background-color: #ffffff;
  color: #594444;
}

.homepagebanner-btn-primary:hover {
  background-color: #f8f9fa;
  color: #594444;
}

.homepagebanner-btn-secondary {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.homepagebanner-btn-secondary:hover {
  background-color: #ffffff;
  color: #594444;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .homepagebanner-title {
    font-size: 2em;
  }

  .homepagebanner-subtitle {
    font-size: 1.2em;
  }

  .homepagebanner-buttons {
    flex-direction: column;
  }

  .homepagebanner-btn {
    width: 100%;
    text-align: center;
  }
  .homepagebanner {
    padding: 50px 20px;
  }
}

@media (max-width: 480px) {
  .homepagebanner-title {
    font-size: 1.5em;
  }

  .homepagebanner-subtitle {
    font-size: 1em;
  }
}
.courseLink {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
}
