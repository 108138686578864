.courseMain-banner {
  position: relative;
  background-color: white;
  color: #2d3748;
  text-align: center;
  overflow: hidden; /* Hide the overflow to prevent icons from showing outside the banner */
}

.courseMain-banner-content {
  margin-bottom: 30px;
}

.courseMain-banner-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #2d3748;
}

.courseMain-banner-subtitle {
  font-size: 1.25rem;
  max-width: 600px;
  margin: 0 auto;
  color: #4a5568;
}

/* Marquee Container */
.courseMain-marquee {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 20px 0;
}

.courseMain-marquee-content {
  display: flex;
  align-items: center;
  animation: marquee 15s linear infinite;
}

/* Marquee Animation */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.courseMain-icon-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 15px;
}

.courseMain-grid-icon {
  font-size: 2.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .courseMain-banner-title {
    font-size: 2rem;
  }

  .courseMain-banner-subtitle {
    font-size: 1rem;
  }

  .courseMain-grid-icon {
    font-size: 2rem; /* Smaller icons on tablets */
  }
}

@media (max-width: 480px) {
  .courseMain-banner-title {
    font-size: 1.75rem;
  }

  .courseMain-banner-subtitle {
    font-size: 0.875rem;
  }

  .courseMain-grid-icon {
    font-size: 1.5rem; /* Even smaller icons on mobile */
  }
}
