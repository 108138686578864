.info-bar {
  background-color: #594444;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.announcement {
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.announcement span {
  text-decoration: underline;
  cursor: pointer;
}

.highlight {
  font-weight: bold;
  color: #ffcc00; /* Bright color to highlight */
}

.enroll-button {
  background-color: #ffcc00;
  color: #594444;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
}

.enroll-button:hover {
  background-color: #e6b800;
}
