.course-advantages-section {
    background-color: #eef2f5;
    padding: 40px;
    border-radius: 8px;
    max-width: 900px;
    margin: 30px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .course-advantages-title {
    text-align: center;
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .course-advantages-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two-column layout */
    gap: 20px;
  }
  
  @media (max-width: 768px) {
    .course-advantages-list {
      grid-template-columns: 1fr; /* Single-column layout on smaller screens */
    }
  }
  
  .course-advantage-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    line-height: 1.5rem;
  }
  
  .course-icon {
    margin-right: 10px;
    width: 24px; /* Fixed width */
    height: 24px; /* Fixed height */
    min-width: 24px; /* Ensures the icon does not shrink */
  }
  
  .course-advantage-item span {
    font-size: 1.1rem;
    color: #555;
  }
  