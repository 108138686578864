.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Close button for the modal */
.modal-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* Heading of the modal */
h2.modal-heading {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  color: #333;
}

/* Form styles */
.modal-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Form group styles */
.modal-form-group {
  display: flex;
  flex-direction: column;
}

/* Label styles */
.modal-form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  color: #555;
}

/* Input and select styles */
.modal-form-group input,
.modal-form-group select {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  transition: border-color 0.3s;
}

/* Focus styles for inputs and selects */
.modal-form-group input:focus,
.modal-form-group select:focus {
  border-color: #007bff;
  outline: none;
}

/* Submit button styles */
.modal-submit-button {
  padding: 12px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-submit-button:disabled {
  background-color: #cccccc; /* Gray background for disabled state */
  color: #666666; /* Gray text color */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  opacity: 0.6; /* Slightly transparent to indicate disabled state */
}
/* Hover effect for submit button */
.modal-submit-button:hover {
  background-color: #0056b3;
}

/* Loader styles */
.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #007bff;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

/* Animation for the loader */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Success message styles */
.modal-success-message {
  color: green;
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
}

/* Error message styles */
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Modal.css */

/* Enroll Error container styles */
.enroll-error-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  color: #721c24;
  font-size: 14px;
  margin-top: 10px;
}

/* Enroll Error icon styles */
.enroll-error-icon {
  color: #721c24;
  font-size: 20px;
  margin-right: 10px;
}

/* Enroll Error message styles */
.enroll-error-message {
  margin: 0;
}

.enroll-thank-you-message {
  margin-top: 1em;
}

/* Responsive Styles for error messages */
@media (max-width: 768px) {
  .enroll-error-container {
    padding: 8px;
    font-size: 13px;
  }

  .enroll-error-icon {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .enroll-error-container {
    padding: 6px;
    font-size: 12px;
  }

  .enroll-error-icon {
    font-size: 16px;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .modal-content {
    padding: 15px;
    overflow: auto;
    height: 75vh;
  }

  h2 {
    font-size: 20px;
  }

  .modal-form-group label {
    font-size: 13px;
  }

  .modal-form-group input,
  .modal-form-group select {
    font-size: 15px;
    padding: 8px;
  }

  .modal-submit-button {
    font-size: 15px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .modal-content {
    max-width: 100%;
    padding: 10px;
  }

  h2.modal-heading {
    font-size: 18px;
  }

  .modal-form-group input,
  .modal-form-group select {
    font-size: 14px;
  }

  .modal-submit-button {
    font-size: 14px;
    padding: 8px;
  }
}

/* Enroll Success container styles */
.enroll-success-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  color: #155724;
  font-size: 14px;
  margin-top: 10px;
}

/* Enroll Success icon styles */
.enroll-success-icon {
  color: #155724;
  font-size: 20px;
  margin-right: 10px;
}

/* Enroll Success message styles */
.enroll-success-message {
  margin: 0;
}

/* Responsive Styles for success messages */
@media (max-width: 768px) {
  .enroll-success-container {
    padding: 8px;
    font-size: 13px;
  }

  .enroll-success-icon {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .enroll-success-container {
    padding: 6px;
    font-size: 12px;
  }

  .enroll-success-icon {
    font-size: 16px;
  }
}
