.page-not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 0 20px;
  background-color: #f5f5f5;
}

.page-not-found-content {
  max-width: 600px;
}

.page-not-found-title {
  font-size: 96px;
  margin: 0;
  color: #594444;
}

.page-not-found-subtitle {
  font-size: 24px;
  margin: 20px 0;
  color: #9c8c8c;
}

.page-not-found-message {
  font-size: 18px;
  color: #666;
}

.page-not-found-back-home-btn {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #594444;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.page-not-found-back-home-btn:hover {
  background-color: #9c8c8c;
}

/* Responsive Design */
@media (max-width: 768px) {
  .page-not-found-title {
    font-size: 72px;
  }

  .page-not-found-subtitle {
    font-size: 20px;
  }

  .page-not-found-message {
    font-size: 16px;
  }

  .page-not-found-back-home-btn {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .page-not-found-title {
    font-size: 48px;
  }

  .page-not-found-subtitle {
    font-size: 18px;
  }

  .page-not-found-message {
    font-size: 14px;
  }

  .page-not-found-back-home-btn {
    padding: 6px 12px;
  }
}
