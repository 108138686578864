.success-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #e8f5e9;
    border: 1px solid #a5d6a7;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 20px auto;
    text-align: center;
  }
  
  .success-message-icon {
    margin-bottom: 15px;
  }
  
  .success-message-text h2 {
    font-size: 24px;
    color: #388e3c;
    margin-bottom: 10px;
  }
  
  .success-message-text p {
    font-size: 16px;
    color: #4a4a4a;
  }