.training-video-container {
  padding: 50px 20px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.training-video-container .content-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.image-wrapper {
  flex: 1;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.video-wrapper {
  flex: 1;
  position: relative;
  max-width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.training-video {
  width: 100%;
  height: auto;
  display: block;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.video-wrapper:hover .video-overlay {
  opacity: 1;
}

.play-icon {
  font-size: 4em;
  color: white;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .training-video-container {
    padding: 40px 10px;
  }

  .training-video-container .content-wrapper {
    flex-direction: column;
  }

  .image-wrapper,
  .video-wrapper {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .play-icon {
    font-size: 3em;
  }
}
