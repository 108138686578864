.contact-us-section {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

.contact-info,
.contact-form {
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-title {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-item {
  display: flex;
  align-items: center;
}

.contact-icon {
  font-size: 1.5em;
  color: #594444;
  margin-right: 15px;
}

.contact-text {
  font-size: 1em;
  color: #9c8c8c;
}

.btn-whatsapp {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.btn-whatsapp:hover {
  background-color: #128c7e;
}

.whatsapp-icon {
  margin-right: 10px;
  font-size: 1.2em;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.mandatory {
  color: #d9534f; /* Red color to indicate mandatory fields */
  font-size: 1.2em; /* Adjust the size as needed */
  margin-left: 5px;
}

.form-group label {
  display: block;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  color: #333;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

/* Default button styles */
.contact-btn-submit {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #594444;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.contact-btn-submit:hover {
  background-color: #9c8c8c;
}

/* Disabled button styles */
.contact-btn-submit:disabled {
  background-color: #d3d3d3; /* Light grey background */
  color: #a9a9a9; /* Dark grey text */
  cursor: not-allowed; /* Change cursor to indicate disabled */
  opacity: 0.6; /* Reduce opacity */
  transition: none; /* Remove transition on disabled state */
}

.recaptchaContainer {
  padding-bottom: 1em;
    justify-content: center;
    align-items: center;
    display: flex;
}

.error-text {
  color: #d9534f;
  font-size: 0.875em;
  position: relative;
  padding-left: 25px;
}

.error-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #d9534f;
}

.success-icon {
  margin-right: 10px;
  color: #28a745;
}

.contact-error-message {
  color: red;
  font-size: 0.9em;
}

.contact-success-message {
  color: #28a745;
  font-size: 1em;
  text-align: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  display: block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .contact-us-section {
    flex-direction: column;
  }
}
