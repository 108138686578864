.rtc-cookie-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  margin-top: 80px;
}

.rtc-cookie-title {
  font-size: 2.5em;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 30px;
}

.rtc-cookie-section {
  margin-bottom: 20px;
}

.rtc-cookie-heading {
  font-size: 1.8em;
  color: #34495e;
  margin-bottom: 10px;
}

.rtc-cookie-text {
  font-size: 1.1em;
  color: #555;
  text-align: justify;
}

.rtc-cookie-text ul {
  list-style-type: disc;
  margin-left: 20px;
}

.rtc-cookie-text li {
  margin-bottom: 10px;
}
