.footer {
  background-color: #594444;
  color: #ffffff;
  padding: 40px 20px;
  font-size: 0.9em;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-section {
  flex: 1;
  min-width: 250px;
}

.footer-title {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: 700;
}

.footer-text {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-text svg {
  font-size: 1.2em;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #9c8c8c;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  color: #ffffff;
  font-size: 1.5em;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #9c8c8c;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
}

/* Center align text and content in mobile view */
@media (max-width: 768px) {
  .footer-content {
    text-align: center;
  }

  .footer-section {
    flex: 1 0 100%;
    text-align: center; /* Added to center align content */
  }

  .footer-text {
    justify-content: center; /* Center-align the text content */
  }

  .social-icons {
    justify-content: center;
  }
}
