.blog-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.blog-list-title {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  background-color: #f0f0f0; /* Banner color */
  padding: 10px;
}

.blog-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.blog-title-section {
  display: flex;
  flex-wrap: wrap; /* Ensure wrapping for smaller screens */
  align-items: center;
}

.blog-item {
  display: flex;
  flex-wrap: wrap; /* Make flexbox wrap for responsiveness */
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.blog-image {
  flex: 0 0 150px; /* Initial width for larger screens */
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
  max-width: 100%; /* Responsive width */
}

.blog-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  flex: 1; /* Allow text to grow */
}

.blog-excerpt {
  font-size: 1em;
  margin-bottom: 10px;
}

.read-more-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  margin-top: 10px;
  transition:
    color 0.3s ease,
    background-color 0.3s ease;
  padding: 5px 10px;
  border: 2px solid #007bff;
  border-radius: 4px;
  display: inline-block; /* Ensures proper margin and padding */
}

.read-more-link:hover {
  color: #fff;
  background-color: #007bff;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap; /* Ensures buttons wrap on smaller screens */
}

.pagination button {
  background-color: #594444;
  color: white;
  border: none;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination .active {
  background-color: #3e3e3e;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-title-section {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center content horizontally */
    text-align: center;
  }

  .blog-item {
    flex-direction: column; /* Stack items vertically */
    text-align: center;
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .blog-image {
    width: 100%;
    height: auto; /* Keep aspect ratio */
    margin-bottom: 10px; /* Margin below image for spacing */
  }

  .blog-title {
    font-size: 1.3em; /* Slightly smaller font size */
    margin: 10px 0; /* Add margin for spacing */
  }

  .blog-excerpt {
    font-size: 1em; /* Keep size consistent */
  }

  .pagination button {
    padding: 8px; /* Reduce padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .blog-list-title {
    font-size: 1.5em;
    padding: 5px; /* Reduce padding for smaller screens */
  }

  .blog-title {
    font-size: 1.1em;
  }

  .blog-excerpt {
    font-size: 0.9em;
  }

  .pagination button {
    padding: 6px; /* Further reduce padding */
    margin: 3px; /* Adjust margin for button spacing */
  }
}
