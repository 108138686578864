.course-enroll-button {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    background: #594444;
    color: #ffffff;
    padding: 15px 30px;
    font-size: 1.1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.course-enroll-button:hover {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    background: #ebe1e1;
    color: #594444;
    padding: 15px 30px;
    font-size: 1.1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}