.faqs-section {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 20px;
}

.faqs-title {
  font-size: 2em;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.faqs-container {
  border-top: 1px solid #ddd;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-item:hover {
  background-color: #f9f9f9;
}

.faq-question {
  font-size: 1.2em;
  color: #594444;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-icon {
  font-size: 1.1em;
  color: #9c8c8c;
  transition: transform 0.3s ease;
}

.faq-answer {
  font-size: 1em;
  color: #666;
  margin-top: 10px;
  line-height: 1.6;
  padding-left: 20px;
}

.open .faq-question {
  color: #333;
}

.open .faq-icon {
  color: #333;
}

@media (max-width: 768px) {
  .faqs-section {
    padding: 20px 15px;
  }

  .faqs-title {
    font-size: 1.5em;
  }

  .faq-question {
    font-size: 1.1em;
  }

  .faq-answer {
    font-size: 0.95em;
  }
}
