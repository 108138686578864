.content-wrapper-mission,
.content-wrapper-vision {
  width: 100%;
}

.content-wrapper-mission {
  position: absolute;
}

.content-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  gap: 0;
  margin: 0 auto;
  position: relative;
}

.content-block {
  position: relative;
  padding: 40px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  z-index: 1;
}

.content-block.mission {
  background-color: #594444;
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
  height: 24em;
}

.content-block.vision {
  clip-path: polygon(100% 100%, 100% 0%, 0% 100%);
  height: 24em;
}

.content-title {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.content-text {
  font-size: 1.2em;
  line-height: 1.6;
}

.content-section-mission {
  float: left;
  text-align: left;
  color: #ffffff;
  width: 40%;
  padding-top: 4em;
}

.content-section-vision {
  float: right;
  text-align: right;
  width: 40%;
  padding-top: 4em;
  color: #594444;
}

/* Responsive styles */
@media (max-width: 768px) {
  .content-block {
    padding: 20px;
    width: 100%;
    max-width: none;
  }

  .content-title {
    font-size: 1.5em;
  }

  .content-text {
    font-size: 1em;
  }

  .content-block.mission {
    background-color: #594444;
    clip-path: none;
    height: 15em;
  }

  .content-block.vision {
    clip-path: none;
    height: 15em;
  }
  .content-wrapper-mission {
    position: relative;
  }

  .content-section-mission,
  .content-section-vision {
    text-align: center;
    width: 100%;
    padding-top: 2em;
  }
}

@media (max-width: 480px) {
  .content-title {
    font-size: 1.2em;
  }

  .content-text {
    font-size: 0.9em;
  }

  .content-block.mission {
    background-color: #594444;
    clip-path: none;
    height: 15em;
  }

  .content-block.vision {
    clip-path: none;
    height: 15em;
  }
  .content-wrapper-mission {
    position: relative;
  }
}
